import applyCaseMiddleware, { ApplyCaseMiddlewareOptions } from 'axios-case-converter';
import axios from 'axios';
import { UUIDV4RegEx } from '@/utils/constants.utils';

const options: ApplyCaseMiddlewareOptions = {
	preservedKeys(input: string): boolean {
		/**
		 * Check if the keys are an UUID string, if that is true don't
		 * transform the keys. Transforming the UUID keys will change and break them.
		 */
		return UUIDV4RegEx.test(input);
	},
};

export const http = applyCaseMiddleware(
	axios.create({
		baseURL: process.env.VUE_APP_BASE_URL,
		headers: {
			'Content-Type': 'application/json',
		},
	}),
	options
);
