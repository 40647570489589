import { computed } from 'vue';
import type { WritableComputedRef } from 'vue';

/**
 * Shorthand for v-model binding, props + emit -> ref
 *
 * @param props
 * @param emit
 * @param key (default `modelValue`)
 */
export function useModelWrapper<
	// eslint-disable-next-line @typescript-eslint/ban-types
	P extends object,
	K extends keyof P,
	Name extends string
>(
	props: P,
	emit: (name: Name, ...args: unknown[]) => void,
	key: K = 'modelValue' as K
): WritableComputedRef<P[K]> {
	/**
	 * Construct update event for v-model
	 * @see {@link https://v3.vuejs.org/guide/component-custom-events.html#v-model-arguments}
	 */
	const event = `update:${key}`;
	return computed({
		get: () => props[key],
		// @ts-expect-error mis-alignment
		set: (value) => emit(event, value),
	});
}
